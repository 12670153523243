import moment from 'moment-timezone';

export const TRACKING_TYPE = {
  PAGE_LOAD: 'page_load',
  REPORT_ACCESS: 'report_access',
};

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  let queryObject = {};
  for (let [key, value] of params.entries()) {
    queryObject[key] = value;
  }

  return queryObject;
}


function inModeReportPage(url) {
  // Create a URL object to parse the URL
  const parsedUrl = new URL(url);

  // Check if the path includes "/mode"
  const hasModeInPath = parsedUrl.pathname.includes('/mode');

  // Check if there are query parameters
  const hasQueryParams = parsedUrl.searchParams.toString() !== '';

  return hasModeInPath && hasQueryParams;
}

const isNewSession = () => {
  const isFirstLoadInSession = !sessionStorage.getItem('hasLoadedBefore');
  if (isFirstLoadInSession && inModeReportPage(window.location.href)) {
    sessionStorage.setItem('hasLoadedBefore', 'true');
    return true;
  }
  return false;
};

const isNewReportAccess = () => {
  const queryParams = getQueryParams();
  const report = queryParams.report;
  const isNewReport = sessionStorage.getItem('lastAccessedReport') !== report;
  if (isNewReport && inModeReportPage(window.location.href)) {
    sessionStorage.setItem('lastAccessedReport', report);
    return true;
  }
  return false;
};

export const track = ({ url, data }) => {
  const dataToSave = {
    ...data,
    accessedAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
  };
  try {
    const host =
      window.location.hostname === 'localhost'
        ? 'http://localhost:8080'
        : window.location.origin;
    fetch(`${host}/${url}`, {
      method: 'POST',
      body: JSON.stringify(dataToSave),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log(`Error saving info`);
        }
        if (response.status === 200) {
          console.log('log updated');
        }
      })
      .then((data) => {
        if (data) {
          console.log('data ', data);
        }
      });
  } catch (error) {
    console.log(error);
  }
};

export const logHomePageLoad = ({ url, data }) => {
  if (isNewSession() || isNewReportAccess()) {
    track({ url, data });
  }
};

export const logReportAccess = ({ url, data }) => {
  track({ url, data });
};
