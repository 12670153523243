import { useOktaAuth } from '@okta/okta-react';
import { logReportAccess } from '../../utils/userTracking';

export const useReportAccessTracking = () => {
  const { authState, authService } = useOktaAuth();
  const getUserInfo = async (reportData) => {
    if (authState?.isAuthenticated) {
      try {
        const user = await authService.getUser();
        if (user) {
          const { email, name } = user;
          logReportAccess({
            url: 'track-report-access',
            data: {
              email,
              name,
              ...reportData
            },
          });
        }
      } catch (error) {
        console.error('Error fetching user information', error);
      }
    }
  };

  const trackReportAccess = (reportData) => {
    getUserInfo(reportData);
  };

  return {
    trackReportAccess,
  };
};
