import React from 'react';
import withStyles from 'react-jss';
import DASHLOGO from '../../Assets/Dash3Logo.png';
import ModeImage from '../../Assets/mode.png';
import Footer from '../Navigation/Footer';
import { Link } from 'react-router-dom';
import modePageImage from '../../Assets/homePageImg.png';
import { usePageLoadTracking } from '../../Hooks/tracking/usePageLoadTracking';

const styles = {
  homePageContainer: {
    fontFamily: 'Rubik',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  container: {
    color: 'black',
    margin: '5%',
  },
  tile: {
    borderTop: '1px solid #000000',
    margin: '20px 0',
    width: '300px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  desc: {
    width: '300px',
    fontSize: '18px',
  },
  p: {
    'a:visited': {
      color: 'black',
    },
  },
  mode: {
    fontSize: '30px',
    color: 'black',
    textDecoration: 'none',
    ':active': {
      textDecoration: 'none',
    },
    fontWeight: 500,
  },
  img: {
    marginTop: '5px',
    height: '25px',
  },
  imgMode: {
    marginRight: '15px',
    height: '25px',
  },
  headerImage: {
    width: '100%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px auto',
    fontWeight: 500,
    fontSize: '36px',
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
};

const HomePage = ({ classes }) => {
  usePageLoadTracking();

  return (
    <>
      <div className={classes.homePageContainer}>
        <div className={classes.title}>Condé Nast Data</div>
        <img
          className={classes.headerImage}
          src={modePageImage}
          alt="header"
        ></img>
        <div className={classes.outerContainer}>
          <div className={classes.container}>
            <a href="https://dash.condenast.com/extensions/directory/index.html">
              <div className={classes.tile}>
                <p>
                  <img className={classes.img} src={DASHLOGO} alt="dash" />
                </p>
              </div>
            </a>
            <div className={classes.desc}>
              DASH provides a complete picture of Condé Nast’s business.
            </div>
          </div>
          <div className={classes.container}>
            <Link to={'/mode'} style={{ textDecoration: 'none' }}>
              <div className={classes.tile}>
                <p>
                  <img className={classes.imgMode} src={ModeImage} alt="mode" />
                  <span className={classes.mode}>MODE Portal</span>
                </p>
              </div>
            </Link>
            <div className={classes.desc}>
              The Mode Portal hosts high-value Mode reports serving the Product,
              Content and Consumer areas of the business.
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(HomePage);
