import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { logHomePageLoad } from '../../utils/userTracking';

export const usePageLoadTracking = (currApp) => {
    const { authState, authService } = useOktaAuth();
    useEffect(() => {
      const getUserInfo = async () => {
        if (authState?.isAuthenticated) {
          try {
            const user = await authService.getUser();
            if (user) {
              const { email, name, locale } = user;
              logHomePageLoad({
                url: 'track-page-access',
                data: {
                  email,
                  name,
                  locale,
                  path: window.location.pathname + window.location.search,
                },
              });
            }
          } catch (error) {
            console.error('Error fetching user information', error);
          }
        }
      };
  
      getUserInfo();
    }, [authState, authService, currApp]);
  
}